import styled from 'styled-components'
import { ButtonBlack, SecondaryTitle, MainText } from 'styles/Global'

export const ContactFormContainer = styled.form`
  margin-top: 0;
  ${SecondaryTitle} {
    margin-bottom: 1.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 5rem;
  }
`

export const ContactFormTop = styled.div`
  margin-bottom: 5rem;
  textarea {
    margin-top: 0.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 2.5rem;
  }
`

export const ContactFormBottom = styled.div`
  margin-bottom: 2rem;
`

export const ContactFormSubmitContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
`

export const ContactFormSubmit = styled(ButtonBlack)`
  outline: none;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:hover:disabled {
    transform: scale(1);
  }
`

export const ContactFormValidation = styled(MainText)<{ isVisible?: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  color: ${({ theme }) => theme.Colors.green};
  margin-bottom: 1rem;
  font-size: 25px;
  svg {
    margin-right: 1rem;
    width: 50px;
    height: auto;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    justify-content: center;
    margin-top: 0;
    font-size: 1rem;
    svg {
      width: auto;
    }
  }
`

export const ContactFormError = styled(MainText)<{ isVisible?: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  color: ${({ theme }) => theme.Colors.red};
  margin: 0 0 2rem;
`

export const ContactFormLoader = styled.div<{ visible: boolean }>`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  position: relative;
  margin-left: 2rem;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: 0.3s;
  display: inline-block;
  vertical-align: middle;

  &::after {
    content: '';
    width: 15px;
    height: 15px;
    border: 3px solid transparent;
    border-top-color: ${({ theme }) => theme.Colors.black};
    border-radius: 50%;
    animation: ${({ visible }) => (visible ? 'spin 1s infinite' : 'unset')};
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: center center;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin: 0 2rem 0 0;
  }
`
