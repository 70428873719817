import styled from 'styled-components'
import ContactBg from 'assets/images/contact/background/heroBg.png'
import ContactBgMobile from 'assets/images/contact/background/heroBgMobile.png'
import { Col2, MainText, PrimaryTitle, Row } from 'styles/Global'

export const ContactHero = styled.section`
  min-height: 70vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  ${PrimaryTitle} {
    margin-bottom: 1rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-height: 40vh;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    min-height: unset;
    margin-top: 95px;
    padding: 6rem 1.5rem 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    max-width: 100%;
    ${PrimaryTitle} {
      margin-bottom: 0.5rem;
    }
  }
`

export const ContactBackground = styled.div`
  background: url('${ContactBg}') no-repeat center -100px;
  margin-bottom: 8.75rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 6rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-image: url('${ContactBgMobile}');
  }
`

export const ContactRow = styled(Row)`
  padding: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    // ${Col2}:first-child {
    //   order: 2;
    // }
  }
`

export const ContactImage = styled(Col2)`
  position: relative;
  top: -150px;
  width: 40%;
  img {
    width: 100%;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    width: 30%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 2rem;
  }
`

export const ContactDecors1 = styled.div`
  max-width: 100px;
  position: relative;
  top: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`

export const ContactDecors2 = styled.div`
  max-width: 60px;
  position: absolute;
  right: 100px;
  bottom: 0;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`

export const ContactSocials = styled.div`
  display: flex;
  align-items: center;
  margin-top: 11.5rem;
  ${MainText} {
    font-family: ${({ theme }) => theme.Fonts.redHat.bold};
    margin-right: 1.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: 6rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-top: 2rem;
    justify-content: flex-end;
    ${MainText} {
      display: none;
    }
  }
`
