import React, { FunctionComponent } from 'react'
import { I18n } from '@lingui/react'
import { t } from '@lingui/macro'
import { StyledTextArea } from './TextArea.style'

interface Props {
  changeEvent: (key: string, label: string) => void
  fieldKey: string
  fieldValue: string
}

const TextArea: FunctionComponent<Props> = ({
  changeEvent,
  fieldKey,
  fieldValue,
}) => {
  return (
    <I18n>
      {/*// @ts-ignore*/}
      {({ i18n }) => (
        <StyledTextArea
          placeholder={i18n._(t`contact.textarea`)}
          onChange={e => changeEvent(fieldKey, e.target.value)}
          value={fieldValue}
          required
        />
      )}
    </I18n>
  )
}

export default TextArea
