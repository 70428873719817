import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
import { Col2, Container, MainText, PrimaryTitle } from 'styles/Global'
import {
  ContactHero,
  ContactBackground,
  ContactImage,
  ContactRow,
  ContactSocials,
  ContactDecors1,
  ContactDecors2,
} from 'styles/pages/contact.style'
import { fetchContactImg } from 'hooks/fetch-contact-img'

import Layout from 'components/Layout'
import Image from 'components/Image'
import ListSocials from 'components/atoms/ListSocials/ListSocials'
import Footer from 'components/molecules/Footer/Footer'
import ContactForm from 'components/organisms/ContactForm/ContactForm'
import Header from 'components/organisms/Header/Header'
import SEO from 'components/Seo'
import { I18n } from "@lingui/react"

interface Props {}

const ContactPage: FunctionComponent<Props> = () => {
  const { avatarContact, decors1, decors2 } = fetchContactImg()

  return (
    <Layout>
      <I18n>
        {({ i18n }) => (
          <SEO
            title="Contact"
            description={i18n._("contact.metaDescription")}
          />
        )}
      </I18n>
      <Header />
      <ContactBackground>
        <ContactHero>
          <PrimaryTitle>
            <Trans id="contact.title">
              Vous souhaitez échanger avec nous ?
            </Trans>
          </PrimaryTitle>
        </ContactHero>
        <Container>
          <ContactRow>
            <Col2>
              <ContactForm />
              <ContactSocials>
                <MainText>
                  <Trans id="contact.socials">Suivez-nous :</Trans>
                </MainText>
                <ListSocials />
              </ContactSocials>
            </Col2>
            <ContactImage>
              <div className="rellax">
                <Image fluid={avatarContact.fluid} alt={avatarContact.alt} />
              </div>
              <ContactDecors1>
                <Image fluid={decors1.fluid} alt={decors1.alt} />
              </ContactDecors1>
              <ContactDecors2>
                <Image fluid={decors2.fluid} alt={decors2.alt} />
              </ContactDecors2>
            </ContactImage>
          </ContactRow>
        </Container>
      </ContactBackground>
      <Footer />
    </Layout>
  )
}

export default ContactPage
