import styled from 'styled-components'
import { Container, SmallText } from 'styles/Global'

export const StyledFooter = styled.footer<{ darkMode?: boolean }>`
  background: ${({ darkMode, theme }) =>
    darkMode ? theme.Colors.mainBlack : 'transparent'};
  color: ${({ darkMode, theme }) =>
    darkMode ? theme.Colors.white : theme.Colors.mainBlack};
`

export const FooterContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    flex-direction: column;
    align-items: flex-end;
    ${SmallText} {
      margin-top: 1rem;
    }
  }
`
