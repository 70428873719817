import styled from 'styled-components'

export const StyledTextArea = styled.textarea`
  border: 2px solid ${({ theme }) => theme.Colors.black};
  resize: none;
  width: calc(100% - 40px);
  min-height: 200px;
  background: ${({ theme }) => theme.Colors.grey};
  font-size: 21px;
  color: ${({ theme }) => theme.Colors.black};
  font-family: ${({ theme }) => theme.Fonts.redHat.medium};
  padding: 1rem 20px;
  outline: none;
  transition: 0.5s;
  &:focus {
    &::placeholder {
      color: ${({ theme }) => theme.Colors.silver};
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.Colors.black};
    transition: 0.5s;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 14px;
    padding: 0.5rem;
    width: calc(100% - 1rem);
  }
`
