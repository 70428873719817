import React, { FunctionComponent, useState, FocusEvent } from 'react'
import {
  StyledInput,
  StyledInputContainer,
  StyledInputLabel,
} from './Input.style'

interface Props {
  label: React.ReactElement
  type: 'text' | 'email'
  changeEvent: (key: string, label: string) => void
  fieldKey: string
  fieldValue: string
}

const Input: FunctionComponent<Props> = ({
  label,
  type,
  changeEvent,
  fieldKey,
  fieldValue,
}) => {
  const [inputFocus, setInputFocus] = useState(false)
  const handleFocus = (
    event: FocusEvent<HTMLInputElement>,
    isFocus: boolean
  ) => {
    setInputFocus(event.currentTarget.value ? true : isFocus)
  }

  return (
    <StyledInputContainer>
      <StyledInput
        type={type}
        onFocus={e => handleFocus(e, true)}
        onBlur={e => handleFocus(e, false)}
        onChange={e => changeEvent(fieldKey, e.target.value)}
        value={fieldValue}
        required
      />
      <StyledInputLabel isFocus={inputFocus}>{label}</StyledInputLabel>
    </StyledInputContainer>
  )
}

export default Input
