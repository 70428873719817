import styled from 'styled-components'

export const StyledInputContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`

export const StyledInput = styled.input`
  font-family: ${({ theme }) => theme.Fonts.redHat.bold};
  font-size: 21px;
  border: 0;
  border-bottom: 2px solid ${({ theme }) => theme.Colors.black};
  padding: 12px 0;
  outline: none;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 17px;
  }
`

export const StyledInputLabel = styled.label<{ isFocus?: boolean }>`
  position: absolute;
  top: ${({ isFocus }) => (isFocus ? '-10px' : '50%')};
  left: 0;
  transform: ${({ isFocus }) => (isFocus ? 'unset' : 'translateY(-50%)')};
  font-size: ${({ isFocus }) => (isFocus ? '14px' : '21px')};
  transition: 0.5s;
  pointer-events: none;
  font-family: ${({ theme, isFocus }) =>
    isFocus ? theme.Fonts.redHat.medium : theme.Fonts.redHat.bold};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${({ isFocus }) => (isFocus ? '12px' : '17px')};
  }
`
