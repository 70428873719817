import React, { FunctionComponent } from 'react'
import { StyledRadio, StyledInputRadio, StyledLabel } from './InputRadio.style'

interface Props {
  label: string
  element: React.ReactElement
  name: string
  changeEvent: (key: string, label: string) => void
  fieldKey: string
  fieldValue: string
}

const InputRadio: FunctionComponent<Props> = ({
  label,
  element,
  name,
  changeEvent,
  fieldKey,
  fieldValue,
}) => {
  return (
    <StyledRadio>
      <StyledInputRadio
        type="radio"
        name={name}
        onChange={() => changeEvent(fieldKey, label)}
        checked={label === fieldValue}
        required
      />
      <StyledLabel>{element}</StyledLabel>
    </StyledRadio>
  )
}

export default InputRadio
