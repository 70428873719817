import React, { FunctionComponent, useRef, useState, MouseEvent } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import { I18n } from '@lingui/react'
import { t, Trans } from '@lingui/macro'
import { SecondaryTitle } from 'styles/Global'
import {
  ContactFormBottom,
  ContactFormContainer,
  ContactFormError,
  ContactFormLoader,
  ContactFormSubmit,
  ContactFormTop,
  ContactFormValidation,
  ContactFormSubmitContainer,
} from './ContactForm.style'
import { ContactFormModel } from 'models/contactForm.model'
import InputRadio from 'components/atoms/InputRadio/InputRadio'
import TextArea from 'components/atoms/TextArea/TextArea'
import Input from 'components/atoms/Input/Input'
import Icons from 'components/atoms/Icons/Icons'
import { IconsName } from 'assets/svg/Icons.enum'

interface Props {}

const ContactForm: FunctionComponent<Props> = () => {
  const recaptchaRef: any = useRef(null)
  const [validCatpcha, validateCaptcha] = useState(false)
  const [mailSent, isMailSent] = useState(false)
  const [errorForm, isError] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [formData, setFormData] = useState<ContactFormModel>({
    subject: '',
    message: '',
    fullName: '',
    email: '',
    company: '',
  })
  const recaptchaV2ClientKey = process.env.GATSBY_RECAPTCHA_V2_CLIENT_KEY
    ? process.env.GATSBY_RECAPTCHA_V2_CLIENT_KEY
    : ''

  const submitForm = (e: MouseEvent) => {
    if (validateForm() && validCatpcha) {
      e.preventDefault()
      sendForm()
    }
  }
  const sendForm = () => {
    isMailSent(false)
    isError(false)
    setLoading(true)

    const postData = {
      message: {
        fromname: `${formData.fullName}`,
        fromemail: `${formData.email}`,
        fromsender: `${formData.subject} - ${formData.company}`,
        message: `${formData.message}`,
      },
    }

    axios({
      method: 'post',
      url: process.env.GATSBY_MAIL_API_URL,
      data: postData,
      headers: {
        'content-type': 'application/json',
      },
    })
      .then(res => {
        if (res.status === 202) {
          isMailSent(true)
          isError(false)
          setLoading(false)
          validateCaptcha(false)
        }
      })
      .catch(error => {
        isMailSent(false)
        isError(true)
        setLoading(false)
        console.error(error)
      })

    recaptchaRef.current.reset()
  }

  const validateForm = () => {
    const dataValues = Object.values(formData)
    const isEmpty = dataValues.some(item => {
      return item === ''
    })
    return !isEmpty
  }

  const fieldOnChange = (fieldKey: string, fieldValue: string) => {
    const newData = { ...formData }
    newData[fieldKey] = fieldValue
    setFormData(newData)
  }

  const executeCaptcha = (value: string | null) => {
    value && validateCaptcha(true)
  }

  return (
    <ContactFormContainer>
      <AnimatePresence>
        {!mailSent && (
          <motion.div
            initial="initial"
            animate="visible"
            exit="hidden"
            variants={{
              initial: {
                opacity: 1,
              },
              visible: {
                opacity: 1,
              },
              hidden: {
                opacity: 0,
              },
            }}
          >
            <ContactFormTop>
              <SecondaryTitle>
                <Trans id="contact.radio.title">Vous avez ...</Trans>
              </SecondaryTitle>
              <InputRadio
                label="Une idée"
                element={<Trans id="contact.radio.item1" />}
                name="subject"
                changeEvent={(key, value) => fieldOnChange(key, value)}
                fieldKey="subject"
                fieldValue={formData.subject}
              />
              <InputRadio
                label="Un produit à créer"
                element={<Trans id="contact.radio.item2" />}
                name="subject"
                changeEvent={(key, value) => fieldOnChange(key, value)}
                fieldKey="subject"
                fieldValue={formData.subject}
              />
              <InputRadio
                label="Besoin d’un coup de pouce..."
                element={<Trans id="contact.radio.item3" />}
                name="subject"
                changeEvent={(key, value) => fieldOnChange(key, value)}
                fieldKey="subject"
                fieldValue={formData.subject}
              />
              <InputRadio
                label="Une question à nous poser..."
                element={<Trans id="contact.radio.item4" />}
                name="subject"
                changeEvent={(key, value) => fieldOnChange(key, value)}
                fieldKey="subject"
                fieldValue={formData.subject}
              />
              <TextArea
                changeEvent={(key, value) => fieldOnChange(key, value)}
                fieldKey="message"
                fieldValue={formData.message}
              />
            </ContactFormTop>
            <ContactFormBottom>
              <SecondaryTitle>
                <Trans id="contact.form.title">
                  Comment pouvons-nous vous joindre ?
                </Trans>
              </SecondaryTitle>
              <Input
                label={<Trans id="contact.form.input1" />}
                type="text"
                changeEvent={(key, value) => fieldOnChange(key, value)}
                fieldKey="fullName"
                fieldValue={formData.fullName}
              />
              <Input
                label={<Trans id="contact.form.input2" />}
                type="email"
                changeEvent={(key, value) => fieldOnChange(key, value)}
                fieldKey="email"
                fieldValue={formData.email}
              />
              <Input
                label={<Trans id="contact.form.input3" />}
                type="text"
                changeEvent={(key, value) => fieldOnChange(key, value)}
                fieldKey="company"
                fieldValue={formData.company}
              />
              <ContactFormSubmitContainer>
                <I18n>
                  {({ i18n }) => (
                    <ContactFormSubmit
                      as="input"
                      type="submit"
                      onClick={submitForm}
                      value={i18n._(t`contact.form.submit`)}
                      disabled={!validCatpcha}
                    />
                  )}
                </I18n>
                <ContactFormLoader visible={isLoading} />
              </ContactFormSubmitContainer>
            </ContactFormBottom>
          </motion.div>
        )}
      </AnimatePresence>
      <ContactFormValidation isVisible={mailSent}>
        <Icons name={IconsName.CHECK} />
        <Trans id="contact.form.success">
          Votre message a été envoyé avec succès, à bientôt !
        </Trans>
      </ContactFormValidation>
      <ContactFormError isVisible={errorForm}>
        <Trans id="contact.form.error">
          Un problème est survenu. Veuillez réesayer dans quelques instants...
        </Trans>
      </ContactFormError>
      {!mailSent && (
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={recaptchaV2ClientKey}
          onChange={executeCaptcha}
        />
      )}
    </ContactFormContainer>
  )
}

export default ContactForm
