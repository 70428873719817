import React, { FunctionComponent } from 'react'
import { Trans } from '@lingui/macro'
import { FooterContainer, StyledFooter } from './Footer.style'
import { SmallText } from 'styles/Global'
import PrivacyLinks from 'components/atoms/PrivacyLinks/PrivacyLinks'

interface Props {
  darkMode?: boolean
}

const Footer: FunctionComponent<Props> = ({ darkMode }) => {
  return (
    <StyledFooter darkMode={darkMode}>
      <FooterContainer>
        <PrivacyLinks />
        <SmallText>
          <Trans id="footer.copyright">
            © 2021 Redfabriq - Tous droits réservés
          </Trans>
        </SmallText>
      </FooterContainer>
    </StyledFooter>
  )
}

export default Footer
