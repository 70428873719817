import styled from 'styled-components'
import { lighten, darken } from 'polished'

export const StyledRadio = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 1.5rem;
  }
`

export const StyledInputRadio = styled.input`
  opacity: 0;
  width: 28px;
  height: 28px;
  margin: 0;
  cursor: pointer;
  &:checked ~ label::before {
    background: ${({ theme }) => theme.Colors.red};
  }
  &:hover:not(:checked) ~ label::before {
    background: ${props => lighten(0.3, props.theme.Colors.red)};
  }
`

export const StyledLabel = styled.label`
  padding-left: 1.5rem;
  font-size: 21px;
  &::before {
    content: '';
    width: 28px;
    height: 28px;
    background: ${({ theme }) => theme.Colors.grey};
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    transition: 0.3s;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 14px;
    padding-left: 1rem;
  }
`
